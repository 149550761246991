import { useContext, useRef, useState } from 'react';
import { PageContext } from '../../context/PageState';
import gsap from 'gsap';
// import GSDevTools from "gsap/GSDevTools";
import { useGSAP } from '@gsap/react';
import cld from '../../constants/cloudinary';
import { AdvancedImage, lazyload } from '@cloudinary/react';

import Scene1, { scene1Timeline } from "./Scenes/Scene1";
import Scene2, { scene2Timeline } from "./Scenes/Scene2";
import Scene3, { scene3Timeline } from "./Scenes/Scene3";
import Scene4, { scene4Timeline } from "./Scenes/Scene4";
import Scene5, { scene5Timeline } from "./Scenes/Scene5";
import Scene6, { scene6Timeline } from "./Scenes/Scene6";
import Scene7, { scene7Timeline } from "./Scenes/Scene7";
import Scene8, { scene8Timeline } from "./Scenes/Scene8";
import Scene9, { scene9Timeline } from "./Scenes/Scene9";
import Scene10, { scene10Timeline } from "./Scenes/Scene10";
import Scene11, { scene11Timeline } from "./Scenes/Scene11";
import Scene12, { scene12Timeline } from "./Scenes/Scene12";
import Scene13, { scene13Timeline } from "./Scenes/Scene13";
import Scene14, { scene14Timeline } from "./Scenes/Scene14";
import CloudAAS, { cloudAASTimeline } from "./Scenes/CloudAAS";
import Nav, { closeNav } from "./Scenes/Nav";
import './index.scss';

// gsap.registerPlugin(GSDevTools);

// timeline var
export const mainTimeline = gsap.timeline({ paused: true });

const WeAreAnimationSlider = () => {

  const { isMobile, isDesktop, theme } = useContext(PageContext);

  const scenesContainer = useRef<any>(null);
  const detectScreenOrientation = () => window.screen.orientation.type === 'landscape-primary' ||
    window.screen.orientation.type === 'landscape-secondary';
  const [showStartBtn, setShowStartBtn] = useState(detectScreenOrientation());

  const enterFullscreenBtn = useRef<HTMLButtonElement | null>(null);
  const exitFullscreenBtn = useRef<HTMLButtonElement | null>(null);

  useGSAP(() => {

    // Helper functions

    function setOrientation() {
      // get orientation
      const isOrientationLandscape = detectScreenOrientation();

      // if mobile and turned to portrait close animation
      if (isOrientationLandscape) {
        // allow device to rotate before binding enter full screen button
        setTimeout(() => {
          if (enterFullscreenBtn.current) enterFullscreenBtn.current.addEventListener("click", enterFullscreen);
        }, 200)
      } else {
        // stop animation
        exitFullscreen();
        if (enterFullscreenBtn.current) enterFullscreenBtn.current.removeEventListener("click", enterFullscreen);
      }

      // detemine whether to show start button or rotate screen on mobile
      setShowStartBtn(isOrientationLandscape);
    };

    function enterFullscreen() {
      if (scenesContainer.current) {
        if (scenesContainer.current.requestFullscreen) {
          scenesContainer.current.requestFullscreen();
        } else if (scenesContainer.current.webkitRequestFullscreen) { /* Safari */
          scenesContainer.current.webkitRequestFullscreen();
        } else if (scenesContainer.current.msRequestFullscreen) { /* IE11 */
          scenesContainer.current.msRequestFullscreen();
        }
        scenesContainer.current.classList.add("fullscreen");
        document.body.style.overflow = "hidden";
        mainTimeline.play();
      }
    }

    function exitFullscreen() {
      if (scenesContainer.current) {
        console.log("Scenes Container");
        scenesContainer.current.classList.remove("fullscreen");
        document.body.removeAttribute("style");
        mainTimeline.pause(0);
        closeNav();

        if (document.fullscreenElement !== null && document.exitFullscreen) {
          document.exitFullscreen();
          //@ts-ignore
        } else if (document.webkitExitFullscreen) { /* Safari */
          //@ts-ignore
          document.webkitExitFullscreen();
          //@ts-ignore
        } else if (document.msExitFullscreen) { /* IE11 */
          //@ts-ignore
          document.msExitFullscreen();
        }
      }
    }

    mainTimeline
      .addLabel("Intro")
      .add(scene1Timeline)
      .addLabel("showSceneName")
      // Show background curve
      .fromTo(".scenes__bg", {
        transform: "translateY(100vh)",
      }, {
        transform: "translateY(0)",
        duration: .8
      })
      .fromTo(".scenes__scene-name", {
        top: "-10%",
        left: "50px",
      }, {
        top: isDesktop ? "3%" : "10%",
        duration: .4
      })
      .fromTo(".scene-name__pill", {
        width: 0
      }, {
        width: "100%",
        duration: .75,
        ease: "power1.out"
      })
      .addLabel("Enterprise Mobility Hardware")
      .fromTo(".scene-name__list", {
        transform: "translateY(100px)"
      }, {
        transform: "translateY(0px)",
        duration: .3,
        ease: "power1.out"
      }, "<.5")
      .add(scene2Timeline)
      .addLabel("Enterprise Payments")
      .to(".scene-name__list", { transform: "translateY(-100px)", duration: .5 }, ">")
      .add(scene3Timeline)
      .addLabel("Beacons and Trackers")
      .to(".scene-name__list", { transform: "translateY(-200px)", duration: .5 }, ">")
      .add(scene4Timeline)
      .addLabel("Wearables")
      .to(".scene-name__list", { transform: "translateY(-300px)" }, ">-.7")
      .add(scene5Timeline)
      .addLabel("Express Experiences")
      .to(".scene-name__list", { transform: "translateY(-400px)", duration: .5 }, ">")
      .add(scene6Timeline)
      .addLabel("Mobile Printing")
      .to(".scene-name__list", { transform: "translateY(-500px)", duration: .5 }, ">")
      .add(scene7Timeline)
      .addLabel("Kiosks")
      .to(".scene-name__list", { transform: "translateY(-600px)", duration: .5 }, ">")
      .add(scene8Timeline)
      // Hide background curve
      .to(".scenes__bg", {
        autoAlpha: 0,
        transform: "translateY(100%)",
        duration: .8
      })
      .addLabel("Enterprise Software Experts")
      .to(".scene-name__list", { transform: "translateY(-700px)", duration: .5 }, ">")
      .add(scene9Timeline)
      .addLabel("Mobile App Development")
      .to(".scene-name__list", { transform: "translateY(-800px)", duration: .5 }, ">")
      .add(scene10Timeline)
      .addLabel("Cloud Development")
      .to(".scene-name__list", { transform: "translateY(-900px)", duration: .5 }, ">")
      .add(scene11Timeline)
      .add(cloudAASTimeline, `<${scene11Timeline.labels["fadeInLoaded"]}`)
      .addLabel("Software Integration")
      .to(".scene-name__list", { transform: "translateY(-1000px)", duration: .5 }, ">")
      .add(scene12Timeline)
      .addLabel("Legacy Migration Specialists")
      .to(".scene-name__list", { transform: "translateY(-1100px)", duration: .5 }, ">")
      .add(scene13Timeline)
      .addLabel("A Partner You Can Trust")
      .to(".scene-name__list", { transform: "translateY(-1200px)", duration: .5, delay: .5 }, ">")
      .to(".scene-name__pill", { paddingLeft: "1.5rem", paddingRight: "1.5rem", duration: .5 }, "<.25")
      .add(scene14Timeline)
      .to(".scene-name__list", { transform: "translateY(-1300px)", duration: .5 }, ">-.25")
      .to(".scene-name__pill", {
        width: 0,
        padding: 0,
        duration: .75,
      }, "<.1")
      .to(".scenes__scene-name", {
        top: "-10%",
        left: "50px",
        autoAlpha: 0
      })
      .call(exitFullscreen)
    ;

    //region DEVELOPMENT
    // GSDevTools.create({ animation: mainTimeline, css: {zIndex: 5} });
    // mainTimeline.seek(mainTimeline.labels["Enterprise Payments"]);
    // mainTimeline.seek(0);
    // if (scenesContainer.current) scenesContainer.current.classList.add("fullscreen");
    //#endregion

    // Event listeners

    // // Close fullscreen on keypress escape
    document.addEventListener('keydown', event => { if (event.key === "Escape") exitFullscreen() });

    // Mobile devies in portait mode will not add the event listener
    // and the event listener must be added on device orientation change landscape
    if (enterFullscreenBtn.current) enterFullscreenBtn.current.addEventListener("click", enterFullscreen);
    if (exitFullscreenBtn.current) exitFullscreenBtn.current.addEventListener("click", exitFullscreen);
    window.addEventListener("orientationchange", setOrientation);

    // Cleanup
    return () => {
      document.removeEventListener('keydown', event => { if (event.key === "Escape") exitFullscreen() });
      if (enterFullscreenBtn.current) enterFullscreenBtn.current.removeEventListener("click", enterFullscreen);
      if (exitFullscreenBtn.current) exitFullscreenBtn.current.removeEventListener("click", exitFullscreen);
      window.removeEventListener("orientationchange", setOrientation);
    };

  });

  return <div className="we-are-animation">
    <div className="start-screen">
      <div>We Are</div>
      <AdvancedImage
        cldImg={cld.image(`logos/ipcmobile-logo${theme.mode ? "-inverted" : ""}`)}
        className="logo"
        plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
        alt="IPCMobile Logo"
      />
      {(!isMobile || showStartBtn) && <button
        className="d-block btn btn-primary mx-auto"
        ref={enterFullscreenBtn}
      >
        What we do
      </button>}
      {(isMobile || !showStartBtn) && <AdvancedImage
        cldImg={cld.image("icons/rotate-to-landscape.svg")}
        className="rotate-to-landscape"
        plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
        alt="Rotate to landscape"
      />}
    </div>
    <div className="we-are-animation__scenes-container" ref={scenesContainer}>
      <div className="we-are-animation__scenes">
        <div className="scenes__gradient-bg"></div>
        <AdvancedImage
          className="scenes__bg"
          cldImg={cld.image("components/we-are-animation/swoosh-bg").format('webp')}
          plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
          alt="background black swoosh"
        />
        <Scene1 />
        <Scene2 />
        <Scene3 />
        <Scene4 />
        <Scene5 />
        <Scene6 />
        <Scene7 />
        <Scene8 />
        <Scene9 />
        <Scene10 />
        <Scene11 />
        <Scene12 />
        <Scene13 />
        <Scene14 />
        <div className="scenes__scene-name">
          <div className='we-are'>We are</div>
          <div className="scene-name__pill">
            <ul className="scene-name__list">
              <li>Enterprise Mobility Hardware</li>
              <li>Enterprise Payments</li>
              <li>Beacons &amp; Trackers</li>
              <li>Wearables</li>
              <li>Express Experiences</li>
              <li>Mobile Printing</li>
              <li>Kiosks</li>
              <li>Enterprise Software Experts</li>
              <li>Mobile App Development</li>
              <li>Cloud Development</li>
              <li>Software Integration</li>
              <li>Legacy Migration Specialists</li>
              <li>your solution partner you can trust</li>
            </ul>
          </div>
        </div>
        <CloudAAS />
        <Nav />
        <button type="button" className="scenes__close close" aria-label="Close" ref={exitFullscreenBtn}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
}

export default WeAreAnimationSlider