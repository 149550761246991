import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from "reactstrap";
import { PageContext } from '../../context/PageState';
import Hyperlink from '../../components/Hyperlink';


const FooterNav: React.FC = () => {
  const { theme } = useContext(PageContext);
  const [isThemeDark, setIsThemeDark] = useState(theme.mode);

  useEffect(() => {
    setIsThemeDark(theme.mode);
  }, [theme.mode])

  return <section className="footer-nav">
    <Container>
      <Row className="footer-nav__content">
        <Col className="footer-nav__branding col-12 col-lg-auto">
          <img className="footer-nav__branding__logo"
            src={`https://res.cloudinary.com/ipcmobile/image/upload/v1648060934/logos/ipcmobile-logo${isThemeDark ? "-inverted" : ""}.svg`}
            alt="IPCMobile Logo"
          />
          <p className="footer-nav__branding__tagline">
            Enterprise Mobility.<br />
            Simpler.
          </p>
        </Col>
        <Col className="footer-nav__links col-lg-7 ms-lg-auto">
          <div className="footer-nav__links__ipcmobile">
            <h3 className="footer-nav__links__subheading">
              IPCMobile
              </h3>
            <ul className="footer-nav__links__container">
              <li>
                <Hyperlink
                  to="/about-us"
                  newWindow={false}
                  className="footer-nav__links__link"
                >
                  <span>About Us</span>
                </Hyperlink>
              </li>
              <li>
                <Hyperlink
                  to="/contact/offices"
                  newWindow={false}
                  className="footer-nav__links__link"
                >
                  <span>Our Offices</span>
                </Hyperlink>
              </li>
              <li>
                <Hyperlink
                  to="/contact/offices"
                  newWindow={false}
                  className="footer-nav__links__link"
                >
                  <span>Contact Us</span>
                </Hyperlink>
              </li>
            </ul>
          </div>
          <div className="footer-nav__links__solutions">
            <h3 className="footer-nav__links__subheading">
              Solutions
              </h3>
            <ul className="footer-nav__links__container">
              <li>
                <Hyperlink
                  to="/software"
                  newWindow={false}
                  className="footer-nav__links__link"
                >
                  <span>Quantum Mobility<br /> Software Suite</span>
                </Hyperlink>
              </li>
              <li>
                <Hyperlink
                  to="/industry/healthcare-solutions"
                  newWindow={false}
                  className="footer-nav__links__link"
                >
                  <span>Healthcare</span>
                </Hyperlink>
              </li>
              <li>
                <Hyperlink
                  to="/industry/hospitality-solutions"
                  newWindow={false}
                  className="footer-nav__links__link"
                >
                  <span>Hospitality</span>
                </Hyperlink>
              </li>
              <li>
                <Hyperlink
                  to="/industry/retail-solutions"
                  newWindow={false}
                  className="footer-nav__links__link"
                >
                  <span>Retail</span>
                </Hyperlink>
              </li>
              <li>
                <Hyperlink
                  to="/industry/supply-chain-solutions"
                  newWindow={false}
                  className="footer-nav__links__link"
                >
                  <span>Supply Chain &amp; Logistics</span>
                </Hyperlink>
              </li>
              <li>
                <Hyperlink
                  to="/industry/travel-solutions"
                  newWindow={false}
                  className="footer-nav__links__link">
                  <span>Travel</span>
                </Hyperlink>
              </li>
              <li>
                <Hyperlink
                  to="/partners"
                  newWindow={false}
                  className="footer-nav__links__link"
                >
                  <span>Partners</span>
                </Hyperlink>
              </li>
              <li>
                <Hyperlink
                  to="/applications"
                  newWindow={false}
                  className="footer-nav__links__link"
                >
                  <span>Applications</span>
                </Hyperlink>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row className="footer-nav__social">
        <Col className="dotted-line-spaced">
          {/* LinkedIn Icon Link */}
          <a
            href={`${window.location.origin}/r/zVy80G`}
            className="footer-nav__social__icon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`https://res.cloudinary.com/ipcmobile/image/upload/v1544812646/icons/linkedin-${isThemeDark ? "white" : "black"}.svg`}
              alt="IPCMobile LinkedIn icon"
            />
          </a>
          {/* YouTube Icon Link */}
          <a
            href={`${window.location.origin}/r/21hJ6j`}
            className="footer-nav__social__icon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`https://res.cloudinary.com/ipcmobile/image/upload/v1544812646/icons/youtube-${isThemeDark ? "white" : "black"}.svg`}
              alt="IPCMobile YouTube icon"
            />
          </a>
          {/* Twitter Icon Link */}
          <a
            href={`${window.location.origin}/r/Dpo84y`}
            className="footer-nav__social__icon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`https://res.cloudinary.com/ipcmobile/image/upload/v1544812635/icons/twitter-${isThemeDark ? "white" : "black"}.svg`}
              alt="IPCMobile Twitter icon"
            />
          </a>
          {/* Instagram Icon Link */}
          <a
            href={`${window.location.origin}/r/5NCZJs`}
            className="footer-nav__social__icon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`https://res.cloudinary.com/ipcmobile/image/upload/v1544812654/icons/instagram-${isThemeDark ? "white" : "black"}.svg`}
              alt="IPCMobile Instagram icon"
            />
          </a>
        </Col>
      </Row>
      <Row className="footer-nav__legal">
        <Col className="col-12 mb-2 col-md-6 footer-nav__legal__copy">
          <span className="d-block d-lg-inline-block">
            Copyright &copy; <span>{new Date().getFullYear()}</span> IPCMobile Inc.&nbsp;
          </span>
          <span className="d-block d-lg-inline-block">
            All rights reserved.
          </span>
          <span className="d-block d-lg-inline-block">
            Google and Pixel are trademarks of Google LLC.
          </span>
          </Col>
        <Col className="col col-md-6 footer-nav__legal__links">
          <Link to="/privacy-policy" className="footer-nav__legal__link footer-nav__legal__privacy">
            <span>Privacy Policy</span>
          </Link>
          <Link to="/cookie-policy" className="footer-nav__legal__link footer-nav__legal__cookies">
            <span>Cookie Policy</span>
          </Link>
          <Link to="/terms-of-service" className="footer-nav__legal__link footer-nav__legal__terms">
            <span>Terms of Service</span>
          </Link>
        </Col>
      </Row>
    </Container>
  </section>;


}


export default FooterNav;