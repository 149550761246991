import React from 'react';
import SubFooter from './SubFooter';
import FooterNav from './FooterNav';

import "./index.scss";

type Props = {
	subfooter?: {
		type: string | undefined;
		heading?: string | undefined;
		subheading?: string | undefined;
		ctaBtn?: string | undefined;
		formUrl?: string | undefined;
	} | undefined;
}

const Footer: React.FC<Props> = (props) => {
	
	return (
		<footer>
			<SubFooter subfooter={props.subfooter} />
			<FooterNav />
		</footer>
	);
};

export default Footer;
