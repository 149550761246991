import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import cld from '../../../constants/cloudinary';
import { AdvancedImage, lazyload, responsive } from '@cloudinary/react';
import { PageContext } from '../../../context/PageState';
import { ICustomerStoriesCarouselId } from '..';

export interface ICustomerStoriesSlide {
  bgImg: {
    src: string;
    alt: string;
  },
  claims: {
    claim1: {
      headline: string;
      shortDescription: string;
    };
    claim2: {
      headline: string;
      shortDescription: string;
    };
  }
  description: string;
  link: {
    text: string;
    url: string;
  },
  indicatorText: string;
}

interface ICustomerStoriesSlides extends ICustomerStoriesCarouselId {
  slides: ICustomerStoriesSlide[];
}

const CarouselSlides: React.FC<ICustomerStoriesSlides> = ({id, slides}) => {
  const { isDesktop } = useContext(PageContext);

  return <div className="position-relative">
    <div className="carousel-inner">
      {slides && slides.map((slide, i) => {
        return <div
          key={i}
          className={['carousel-item', i === 0 ? 'active' : undefined].join(' ')}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="claims">
                  <div className="claim claim-1">
                    <div className="claim__headline">
                      {slide.claims.claim1.headline}
                    </div>
                    <div className="claim__description">
                      {slide.claims.claim1.shortDescription}
                    </div>
                  </div>
                  <div className="claim claim-2">
                    <div className="claim__headline">
                      {slide.claims.claim2.headline}
                    </div>
                    <div className="claim__description">
                      {slide.claims.claim2.shortDescription}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AdvancedImage
            cldImg={cld.image("customer-stories/" + slide.bgImg.src).format('webp').quality('auto')}
            alt={slide.bgImg.alt}
            className={["d-block w-100 rounded", !isDesktop ? "mb-3" : undefined].join(' ')}
            plugins={[lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 }), responsive({ steps: [600, 960] })]}
          />
          {!isDesktop && <div className="container">
              <div className="row">
                <div className="col">
                  <p>
                    {slide.description && slide.description}
                  </p>
                </div>
              </div>
          </div>}
          {isDesktop && <div className='carousel-caption'>
            <p>{slide.description && slide.description}</p>
            {(typeof slide.link.url === "string" && slide.link.url.length !== 0) && <Link
              to={slide.link.url}
            >
              {slide.link.text} &gt;
            </Link>}
          </div>}
        </div>
      })}
    </div>
    <button className="carousel-control-prev" type="button" data-bs-target={`#${id}`} data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target={`#${id}`} data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Next</span>
    </button>
  </div>
}

export default CarouselSlides