import React, { useEffect } from 'react';
import { AuthContext, db } from '../../context/AuthState';
import { PageContext } from '../../context/PageState';
import { doc, getDoc } from "firebase/firestore";
import { IState, IAction } from '../../types';
import INITIAL_STATE from "../../constants";
import Layout from "../../partials/Layout";
import BillboardSlider from '../../components/BillboardHomePage2024';
import WeAreAnimation from '../../components/WeAreAnimation';
import JumpPad from '../../components/JumpPad';
import CustomerStories from '../../components/CustomerStories';
import TestimonialSlider from '../../components/TestimonialSlider';
import { hardwareLinks, softwareLinks, customerStories, customerTestimonials } from "./linkData";
import cld from '../../constants/cloudinary';
import { AdvancedImage, lazyload, responsive } from '@cloudinary/react';
import CardSlider from '../../components/CardSlider';

import './index.scss';

const Home: React.FC<any> = props => {

  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);
  const { user } = React.useContext(AuthContext);
  const { isDesktop } = React.useContext(PageContext);

  useEffect((): any => {
    if (!user) return;

    const getPageData = async () => {
      const docRef = doc(db, "/pages/home");
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) return;

      const data = docSnap.data();
      dispatch({
        type: "SET_PAGE_STATE",
        payload: {
          dbLoaded: true,
          metadata: data.metadata,
          subfooter: data.subfooter,
        }
      });

      window.gtag('event', 'page_view', {
        page_title: data.metadata.title,
        page_location: window.location.pathname
      });

      document.title = data.metadata.title;

    }

    getPageData();

  }, [user]);

  document.body.className = 'layout home';

  if (!state.dbLoaded) return <></>;

  return <Layout {...state}>
    <section className='billboard'>
      <BillboardSlider />
    </section>
    <WeAreAnimation />
    <section className='hardware-banner position-relative text-white'>
      <div className="container my-5 my-lg-0">
        <div className="row">
          <div className="col">
            <div className="hardware-banner__content">
              <h2>A trusted world-leading hardware company helping you complete your enterprise experiences.</h2>
              <p>Mobile enterprise payments, ticketing, scanning, printing, asset tracking, supply chain and logistics, and self-service leaders.</p>
            </div>
          </div>
        </div>
      </div>
      <AdvancedImage
        cldImg={cld.image("home/v2/banner-ipcmobile-hardware").format('webp').quality('auto')}
        plugins={[lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 }), responsive({ steps: [600, 1200, 2400] })]}
        alt="IPCMobile hardware HaloRing, Linea Pro Rugged for iPhone 13 and iPhone 14, and QuantumPay Reader"
      />
    </section>
    <section className='hardware-jumppad'>
      <JumpPad links={hardwareLinks} />
    </section>
    <section className='software-banner position-relative text-white' style={{
      backgroundImage: 'url(' + cld.image(`home/v2/banner-ipcmobile-software-${isDesktop ? 'lg' : 'sm'}`).format('webp').quality('auto').toURL() + ')',
      backgroundSize: 'cover'
    }}>
      <div className="container my-5 my-lg-0">
        <div className="row">
          <div className="col">
            <div className="software-banner__content mx-auto text-center">
              <h2>An award-winning software development partner</h2>
              <p>Enterprise software experts in mobile and cloud initiatives specializing in quality, scale, speed, and success.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='software-jumppad'>
      <JumpPad links={softwareLinks} />
    </section>
    <section className='customer-stories'>
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>Going above and beyond</h2>
            <p className='mb-5'>A trusted partner that can deliver hardware and software solutions for enterprise's most demanding challanges.</p>
          </div>
        </div>
      </div>
      <CustomerStories id="customer-success-stories" stories={customerStories} />
    </section>
    <section className='customer-testimonials'>
      <TestimonialSlider id='customer-testimonials' testimonials={customerTestimonials} />
    </section>
    <section className='announcements'>
      <div className="container mb-4">
        <div className="row">
          <div className="col">
            <h2>Latest News and Blogs</h2>
          </div>
        </div>
      </div>
      <CardSlider />
    </section>
  </Layout>
}

const reducer = (state: IState, action: IAction): IState => {
  const { type, payload } = action;
  switch (type) {
    case "SET_PAGE_STATE":
      return {
        ...state,
        ...payload
      }
    default:
      return state;
  }
}

export default Home