import { useEffect, useRef, useContext } from "react";
import { PageContext } from "../../context/PageState";
import { Carousel } from "bootstrap";
import cld from "../../constants/cloudinary";
import { AdvancedImage } from "@cloudinary/react";
import "./index.scss";

const BillboardHomePage2024 = () => {

    const { isDesktop, modalForm, theme } = useContext(PageContext);

    const homeBilboardRef = useRef<HTMLDivElement | null>(null);

    const mobileNexusConnectMultiDevice = useRef<any>(theme.mode ?
        cld.image("home/v2/nexusconnect-uhf-multi-device-dark") :
        cld.image("home/v2/nexusconnect-uhf-multi-device-light")
    );
    const mobileHalringColoredBumpers = useRef<any>(cld.image("device-overview/haloring-100/haloring-colored-bumpers"));

    useEffect(() => {

        let carousel: Carousel;

        if (homeBilboardRef && homeBilboardRef.current) {
            const carouselOptions: { [key: string]: any } = {
                interval: 4000,
                ride: true,
            };

            carousel = new Carousel(homeBilboardRef.current, carouselOptions);
            carousel.cycle();

            //  disable video downloads
            document.querySelectorAll("video").forEach((video) => {
                video.setAttribute("controlslist", "nodownload");
                video.setAttribute("oncontextmenu", "return false");
            });

        }

        mobileNexusConnectMultiDevice.current = theme.mode ?
            cld.image("home/v2/nexusconnect-uhf-multi-device-dark") :
            cld.image("home/v2/nexusconnect-uhf-multi-device-light")
            ;

        return () => {
            if (carousel) carousel.dispose();
        }

    }, [theme.mode, isDesktop]);

    return <div ref={homeBilboardRef} id="home-billboard" className="cp_billboard-homepage-2024 carousel slide">
        <div className="carousel-indicators">
            <div className="carousel-indicators__container">
                <button type="button" data-bs-target="#home-billboard" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#home-billboard" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#home-billboard" data-bs-slide-to="2" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#home-billboard" data-bs-slide-to="3" aria-label="Slide 4"></button>
            </div>
        </div>
        <div className="carousel-inner">
            <div className="carousel-item lpg active">
                <div className="container">
                    <div className="row">
                        <div className="col position-relative">
                            <div className="item__content">
                                <h2>Linea Pro Google Pixel</h2>
                                <p>
                                    The most powerful, AI-Optimized&nbsp;
                                    <span className="d-none d-lg-block"></span>
                                    smart data capture solution ever
                                </p>
                                <div className="cta-links">
                                    <a
                                        href="/devices/linea-pro-google-pixel"
                                        className="btn btn-primary me-3"
                                    >
                                        Learn More
                                    </a>
                                    <button
                                        className={`btn btn-link-${theme.mode ? "light" : "secondary"} learn-more`}
                                        data-toggle="modal"
                                        onClick={() => modalForm.toggle()}
                                    >
                                        Contact Sales
                                        <img
                                            className="btn-icon right-arrow"
                                            src={`https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/chevron-right-${theme.mode ? "white" : "black"}.svg`}
                                            alt="Chevron point to the right"
                                        />
                                    </button>
                                </div>
                            </div>
                            {isDesktop && <video
                                className="linea-pro-google-pixel-battery-header" playsInline autoPlay loop muted>
                                <source src="https://res.cloudinary.com/ipcmobile/video/upload/v1715641952/home/v2/linea-pro-google-pixel-battery-header.mov" type="video/quicktime" />
                                <source src="https://res.cloudinary.com/ipcmobile/video/upload/v1715641952/home/v2/linea-pro-google-pixel-battery-header.webm" type="video/webm" />
                                <source src="https://res.cloudinary.com/ipcmobile/video/upload/v1715641952/home/v2/linea-pro-google-pixel-battery-header.mp4" type="video/mp4" />
                            </video>}
                            {!isDesktop && <AdvancedImage
                                className="linea-pro-google-pixel-battery-header"
                                cldImg={cld.image('home/v2/linea-pro-google-pixel-color-variations.gif')}
                            />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="carousel-item lpr">
                <div className="container">
                    <div className="row">
                        <div className="col position-relative">
                            <div className="item__content">
                                <h2>Linea Pro for iOS</h2>
                                <p>
                                    15+ years of developing the most reliable&nbsp;
                                    <span className="d-none d-lg-block"></span>
                                    mobile solutions for iOS devices

                                </p>
                                <div className="cta-links">
                                    <a
                                        href="/devices?platform=iOS"
                                        className="btn btn-primary me-3"
                                    >
                                        Learn More
                                        <img
                                            className="btn-icon right-arrow"
                                            src="https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/chevron-right-white.svg"
                                            alt="Chevron point to the right"
                                        />
                                    </a>
                                    <button
                                        className={`btn btn-link-${theme.mode ? "light" : "secondary"} learn-more`}
                                        data-toggle="modal"
                                        onClick={() => modalForm.toggle()}
                                    >
                                        Contact Sales
                                        <img
                                            className="btn-icon right-arrow"
                                            src={`https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/chevron-right-${theme.mode ? "white" : "black"}.svg`}
                                            alt="Chevron point to the right"
                                        />
                                    </button>
                                </div>
                            </div>
                            {isDesktop && <video
                                className="linea-pro-for-iphone-13-14" playsInline autoPlay loop muted>
                                <source src="https://res.cloudinary.com/ipcmobile/video/upload/v1720646148/home/v2/linea-pro-rugged-for-iphone-13-14.mov" type="video/quicktime" />
                                <source src="https://res.cloudinary.com/ipcmobile/video/upload/v1720646148/home/v2/linea-pro-rugged-for-iphone-13-14.webm" type="video/webm" />
                                <source src="https://res.cloudinary.com/ipcmobile/video/upload/v1720646148/home/v2/linea-pro-rugged-for-iphone-13-14.mp4" type="video/mp4" />
                            </video>}
                            {!isDesktop && <AdvancedImage
                                className="linea-pro-for-iphone-13-14"
                                cldImg={cld.image('home/v2/linea-pro-rugged-for-iphone-13-14-mobile.gif')}
                            />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="carousel-item nexusconnect-uhf">
                <div className="container">
                    <div className="row">
                        <div className="col position-relative">
                            <div className="item__content">
                                <h2>NexusConnect UHF</h2>
                                <p>
                                    Inventory location and counting&nbsp;
                                    <span className="d-none d-lg-block"></span>
                                    at the speed of light
                                </p>
                                <div className="cta-links">
                                    <a
                                        href="/devices/lp-nexusconnect-uhf"
                                        className="btn btn-primary me-3"
                                    >
                                        Learn More
                                    </a>
                                    <button
                                        className={`btn btn-link-${theme.mode ? "light" : "secondary"} learn-more`}
                                        data-toggle="modal"
                                        onClick={() => modalForm.toggle()}
                                    >
                                        Contact Sales
                                        <img
                                            className="btn-icon right-arrow"
                                            src={`https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/chevron-right-${theme.mode ? "white" : "black"}.svg`}
                                            alt="Chevron point to the right"
                                        />
                                    </button>
                                </div>
                            </div>
                            <div className="os-logos">
                                <AdvancedImage
                                    className="android-logo"
                                    cldImg={cld.image("components/we-are-animation/android-wearables")}
                                    alt="Google Android"
                                />
                                <AdvancedImage
                                    className="apple-logo"
                                    cldImg={cld.image("customer-logos/apple-logo-white")}
                                    alt="Apple iOS"
                                />
                            </div>
                            {isDesktop && <video
                                className="nexusconnect-uhf-multi-device" playsInline autoPlay loop muted>
                                <source src="https://res.cloudinary.com/ipcmobile/video/upload/v1710956748/home/v2/nexusconnect-uhf-multi-device.mov" type="video/quicktime" />
                                <source src="https://res.cloudinary.com/ipcmobile/video/upload/v1710956748/home/v2/nexusconnect-uhf-multi-device.webm" type="video/webm" />
                                <source src="https://res.cloudinary.com/ipcmobile/video/upload/v1710956748/home/v2/nexusconnect-uhf-multi-device.mp4" type="video/mp4" />
                            </video>}
                            {!isDesktop && <AdvancedImage
                                className="nexusconnect-uhf-multi-device"
                                cldImg={mobileNexusConnectMultiDevice.current}
                            />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="carousel-item haloring">
                <div className="container">
                    <div className="row">
                        <div className="col position-relative">
                            <div className="item__content">
                                <h2>HaloRing</h2>
                                <p>
                                    The world’s lightest standalone&nbsp;
                                    <span className="d-none d-lg-block"></span>
                                    Android wearable scanner
                                </p>
                                <div className="cta-links">
                                    <a
                                        href="/devices/haloring-100"
                                        className="btn btn-primary me-3"
                                    >
                                        Learn More
                                    </a>
                                    <button
                                        className={`btn btn-link-${theme.mode ? "light" : "secondary"} learn-more`}
                                        data-toggle="modal"
                                        onClick={() => modalForm.toggle()}
                                    >
                                        Contact Sales
                                        <img
                                            className="btn-icon right-arrow"
                                            src={`https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/chevron-right-${theme.mode ? "white" : "black"}.svg`}
                                            alt="Chevron point to the right"
                                        />
                                    </button>
                                </div>
                            </div>
                            {isDesktop && <video
                                className="halring-colored-bumpers" playsInline autoPlay loop muted>
                                <source src="https://res.cloudinary.com/ipcmobile/video/upload/v1710956757/home/v2/haloring-colored-bumpers-spinning.mov" type="video/quicktime" />
                                <source src="https://res.cloudinary.com/ipcmobile/video/upload/v1710956757/home/v2/haloring-colored-bumpers-spinning.webm" type="video/webm" />
                                <source src="https://res.cloudinary.com/ipcmobile/video/upload/v1710956757/home/v2/haloring-colored-bumpers-spinning.mp4" type="video/mp4" />
                            </video>}
                            {!isDesktop && <AdvancedImage
                                className="halring-colored-bumpers"
                                cldImg={mobileHalringColoredBumpers.current}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#home-billboard" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#home-billboard" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
        <div className="swoosh"></div>
    </div>
}

export default BillboardHomePage2024;