import React, { useReducer } from 'react';
import { db } from '../../../context/AuthState';
import { collection, query, where, getDocs } from "firebase/firestore";
import { orderBy } from 'lodash';
import Cards from '../../../components/Cards';

import { IAction } from "../../../types";
const StaticDevices = require('../Static/Devices.json');

type Props = {
  dbLoaded: boolean;
  sections: any;
}

interface IState {
  dbLoaded: boolean;
  sections: any;
  devices: any;
}

/**
    If in development mode devices are pulled from staging firestore.
    In production mode devices are pulled from static payload found in ../Static/Devices.json;
*/

const initialState: IState = {
  dbLoaded: false,
  sections: [],
  devices: {}
};

const CustomInitHits: React.FC<Props> = props => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  React.useEffect(() => {

    const getPageData = async () => {
      // Only used to generate new static device state
      let devices: any = {
        'linea-pro': [],
        'quantum': [],
        'halo': [],
        'infinea-tab': [],
        'dpp': [],
        'mp': []
      };

      const q = query(
        collection(db, "products"),
        where("isPublic", "==", true)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {

        const { title, compatibility, blurb, slug, family, objectID, family_order } = doc.data();
        let images = doc.data().images.slice(0, 1);
        const device = { title, compatibility, images, blurb, slug, family, objectID, family_order };
        device.compatibility = device.compatibility.reverse();

        // filter each device into ints own family unordered
        switch (device.family) {
          case 'Linea Pro':
            devices['linea-pro'].push(device);
            break;
          case 'Halo':
            devices.halo.push(device);
            break;
          case 'Quantum':
            devices.quantum.push(device);
            break;
          case 'Infinea Tab':
            devices['infinea-tab'].push(device);
            break;
          case 'DPP':
            devices.dpp.push(device);
            break;
          case 'MP':
            devices.mp.push(device);
            break;
          default:
            break;
        }

        //order each device family
        for (const key in devices) {
          devices[key] = orderBy(devices[key], ['family_order'], ['desc']);
        }
        //merge printer familes together
        devices['mobile-printers'] = devices.dpp.concat(devices.mp);

        dispatch({
          type: "SET_SECTIONS",
          payload: {
            sections: props.sections,
            devices
          }
        })

      });

      // Print out devices JSON Payload for Devices.json
      console.info(devices);

    }

    if (process.env.REACT_APP_IS_PUSHING_LIVE === "true") {

      dispatch({
        type: "SET_SECTIONS",
        payload: {
          sections: props.sections,
          devices: StaticDevices,
        }
      });

    } else {
      getPageData();
    }

  }, [props.sections]);

  const familyOrder: string[] = [
    'linea-pro',
    'halo',
    'quantum',
    'infinea-tab',
    'mobile-printers'
  ]

  if (!props.dbLoaded) return <React.Fragment></React.Fragment>

  return <React.Fragment>
    {familyOrder.map((sec: any, familyIndex: number) => {

      if (
        state.devices[sec] === undefined ||
        state.devices[sec].length === 0 ||
        state.sections[sec] === undefined
      ) return '';

      return <section key={familyIndex} className={`${sec}-sec`}>
        <h2 className='h3 fw-bold'>{state.sections[sec].headline && state.sections[sec].headline}</h2>
        <p>{state.sections[sec].description && state.sections[sec].description}</p>
        <div className="row">
          {state.devices[sec].map((device: any, i: number) => <Cards key={i} {...device} />)}
        </div>
      </section>

    })}
  </React.Fragment>
}

const Reducer = (state: IState, action: IAction): IState => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_SECTIONS':
      return {
        ...state,
        sections: payload.sections,
        devices: payload.devices,
      }
    default:
      return state;
  }
}

export default CustomInitHits;