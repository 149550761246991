import { ICustomerStory } from '../../components/CustomerStories';
import { IJumpPadLink } from '../../components/JumpPad';
import { ITestimonialSliderTestimonial } from '../../components/TestimonialSlider';

export const hardwareLinks: IJumpPadLink[] = [
  {
    title: "iOS Tech",
    className: "ios-tech",
    url: "/products/ios-tech",
    image: {
      src: "home/v2/hardware/apple-tech",
      alt: "linea pro rugged device with an iPhone device"
    },
    hidden: false
  },
  {
    title: "Android Tech",
    className: "android-tech",
    url: "/products/android-tech",
    image: {
      src: "home/v2/hardware/android-tech",
      alt: "a Linea Pro Rugged for Google with a Google Pixel"
    },
    hidden: false
  },
  {
    title: "Payment Tech",
    url: "/products/payment-tech",
    image: {
      src: "home/v2/hardware/payment-tech",
      alt: "A linea Pro with a QuantumPay Reader showing different ways to take payments"
    },
    hidden: false
  },
  {
    title: "Wearables",
    url: "/products/wearable-tech",
    image: {
      src: "home/v2/hardware/haloring-wearables",
      alt: "a HaloRing device"
    },
    hidden: false
  },
  {
    title: "Self-Service Kiosks",
    url: "/products/self-service-kiosks",
    image: {
      src: "home/v2/hardware/kiosks",
      alt: "a kiosk device"
    },
    hidden: false
  },
  {
    title: "Mobile Printers",
    url: "/products/mobile-printers",
    image: {
      src: "home/v2/hardware/printer",
      alt: "a printer"
    },
    hidden: false
  },
  {
    title: "Beacons",
    url: "/products/beacons",
    image: {
      src: "home/v2/hardware/beacon",
      alt: "beacon tracking device"
    },
    hidden: false
  },
  {
    title: "Enterprise Tablets",
    url: "/products/enterprise-tablets",
    image: {
      src: "home/v2/hardware/enterprise-tablets",
      alt: "a tablet device"
    },
    hidden: false
  },
  {
    title: "All-in-One Android Payment",
    url: "/products/all-in-one-android-payments",
    image: {
      src: "home/v2/hardware/all-in-one-device",
      alt: "an all-in-one android payment device"
    },
    hidden: false
  },
  {
    title: "View All Hardware",
    url: "/devices",
    image: {
      src: "icons/view-more",
      alt: "a plus sign"
    },
    hidden: false
  }
]

export const softwareLinks: IJumpPadLink[] = [
  {
    title: "Mobile Development",
    url: "/software/mobile-development",
    image: {
      src: "home/v2/software/mobile-development",
      alt: "mobile device with several a play and code icon on top"
    },
    hidden: false
  },
  {
    title: "Cloud Development",
    url: "/software/cloud-development",
    image: {
      src: "home/v2/software/cloud-development",
      alt: "cloud showing code brackets"
    },
    hidden: false
  },
  {
    title: "QuantumPay",
    url: "/software/quantumpay",
    image: {
      src: "home/v2/software/quantumpay",
      alt: "QuantumPay logo icon"
    },
    hidden: false
  },
  {
    title: "Extended Development Team",
    url: "/software/extended-development-team",
    image: {
      src: "home/v2/software/extended-team",
      alt: "shape of three peopl outlined"
    },
    hidden: true
  },
  {
    title: "Rapid Deployment Kits",
    url: "/software/rapid-deployment-kits",
    image: {
      src: "home/v2/software/rapid-deployment-kits",
      alt: "Letters R, D, and K inside a square with rounded corners"
    },
    hidden: false
  },
  {
    title: "Quality Assurance, Testing, and Software Support",
    url: "/software/quality-assurance-testing-support",
    image: {
      src: "home/v2/software/quality-assurance",
      alt: "A ribbon with a checkmark in the center"
    },
    hidden: true
  },
  {
    title: "Bespoke Software Development ",
    url: "/software/enterprise-bespoke-software-development",
    image: {
      src: "home/v2/software/bespoke-software-development",
      alt: "A pencil inside two dotted sqaures depicting a blueprint"
    },
    hidden: false
  },
  {
    title: "View All Software",
    url: "/",
    image: {
      src: "icons/view-more",
      alt: "a plus sign"
    },
    hidden: false
  }
]

export const customerStories: ICustomerStory[] = [
  {
    customer: {
      logo: {
        src: "customer-logos/unknown-identity-black",
        alt: "Unidentified customer logo",
      },
      name: "Customer",
    },
    slide: {
      bgImg: {
        src: "realtime-ticket-validation-and-payments",
        alt: "A hand holding Linea Pro Rugged hardware in a warehouse environment",
      },
      claims: {
        claim1: {
          headline: "15.3 Million",
          shortDescription: "People in transit in one of North America's largest transportaion networks.",
        },
        claim2: {
          headline: "Future-ready",
          shortDescription: "Linea Pro and QuantumPay scalability and expansion"
        }
      },
      description: "Helping a transportation network accept payments and validate boarding tickets in real-time while in transit.",
      link: {
        text: "Learn More",
        url: "",
      },
      indicatorText: "Ticket Validation and Payments",
    }
  },
  {
    customer: {
      logo: {
        src: "customer-logos/unknown-identity-black",
        alt: "Unidentified customer logo",
      },
      name: "Customer",
    },
    slide: {
      bgImg: {
        src: "deprecated-to-future-ready-scanning",
        alt: "A train in transit through a forest terrain",
      },
      claims: {
        claim1: {
          headline: "1,200 manufacturer brands",
          shortDescription: "A major semiconductors and electronic components distributor.",
        },
        claim2: {
          headline: "White glove service",
          shortDescription: "90 days from testing to integration ",
        }
      },
      description: "From deprecated barcode scanning to a future-proof iOS enterprise-grade platform.",
      link: {
        text: "Learn More",
        url: "",
      },
      indicatorText: "Pick, pack, and ship",
    }
  }
]

export const customerTestimonials: ITestimonialSliderTestimonial[] = [
  {
    backgroundImage: "home/v2/customer-testimonials/kpt/background",
    className: "kpt",
    entityName: "King Power Traveler Co. Ltd.",
    entityLogoSrc: "home/v2/customer-testimonials/kpt/logo",
    representative: {
      fullName: "Rakhita Jayawardena",
      title: "President",
      quote: "We have been partnering with IPC for our POS software services since 2014, and the efficiency and reliability of IPC's software solutions have significantly streamlined our processes, making it seamless for us to manage sales reporting and inventory. The real-time data insights provided by their software have empowered us to make informed decisions, contributing to the overall success of our inflight duty-free services."
    }
  },
  {
    backgroundImage: "home/v2/customer-testimonials/oai/background",
    className: "oai",
    entityName: "Omni Air International",
    entityLogoSrc: "home/v2/customer-testimonials/oai/logo",
    representative: {
      fullName: "Art Seabolt",
      title: "Vice President of Technology",
      quote: "Working with IPCMobile allowed us to ensure the team was able to deliver the critical components we needed for our airline. The in-flight team was able to adopt a modern approach which has improved our operational effectiveness and customer satisfaction while in the skies."
    }
  },
  {
    backgroundImage: "home/v2/customer-testimonials/ocfa/background",
    className: "ocfa",
    entityName: "Orange County Fire Association",
    entityLogoSrc: "home/v2/customer-testimonials/ocfa/logo",
    representative: {
      fullName: "Matt Job",
      title: "Service Center Specialist",
      quote: "[IPCMobile] let us borrow a bunch of their equipment to show everybody that this is something that could work for us. What started as just an inventory scanning solution became asset tracking, inventory, shipping, and receiving."
    }
  }
]