import React, { useEffect } from 'react'
import { PageContext } from '../../context/PageState';
import CarouselIndicators from './subcomponents/CarouselIndicators';
import CarouselSlides, { ICustomerStoriesSlide } from './subcomponents/CarouselSlides';
import { ICustomerStoryCustomer } from './subcomponents/CarouselLogoIndicator';
import { Carousel } from 'bootstrap';

import './index.scss';

export interface ICustomerStoriesCarouselId {
  id: string;
}

export interface ICustomerStory {
  customer: ICustomerStoryCustomer,
  slide: ICustomerStoriesSlide
}

interface ICustomerStories extends ICustomerStoriesCarouselId {
  stories: ICustomerStory[]
}

const CustomerStories: React.FC<ICustomerStories> = ({id, stories}) => {
  const { isDesktop } = React.useContext(PageContext);
  useEffect(() => {
    const carousel = document.querySelector("#" + id);
    let carouselEl: Carousel;
    const carouselElOptions = {
      // interval: 4000,
      // ride: isDesktop
    }

    if (carousel) {
      const carouselLogoIndicators = carousel.querySelector('.logo-indicators');
      carousel.addEventListener('slide.bs.carousel', function (e) {
        if (carouselLogoIndicators) {
          const indicators = carouselLogoIndicators.children;
          if (indicators) {
            indicators[e.from].classList.remove('active');
            indicators[e.to].classList.add('active');
          }
        }
      })

      carouselEl = new Carousel(carousel, carouselElOptions);
      carouselEl.cycle();
    }

  }, [id, isDesktop])

  if (isDesktop) return <div className="cp_customer-stories">
    <div id={id} className="carousel slide">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-3">
            <CarouselIndicators
              id={id}
              indicatorText={stories.map(story => story.slide.indicatorText)}
            />
          </div>
          <div className="col col-lg-9">
            <CarouselSlides
              id={id}
              slides={stories.map(story => story.slide)}
            />
          </div>
        </div>
        {/* <div className="row">
          <div className="col">
            <CarouselLogoIndicators
              id={id} 
              customers={stories.map(story => story.customer)} 
            />
          </div>
        </div> */}
      </div>
    </div>
  </div>

  return <div className="cp_customer-stories">
    <div id={id} className="carousel slide">
      <div className="container">
        <div className="row">
          <div className="col">
            <CarouselIndicators
              id={id}
              indicatorText={stories.map(story => story.slide.indicatorText)}
            />
          </div>
        </div>
      </div>
      <CarouselSlides
        id={id}
        slides={stories.map(story => story.slide)}
      />
      {/* <div className="container">
        <div className="row">
          <div className="col">
            <CarouselLogoIndicators 
              id={id} 
              customers={stories.map(story => story.customer)} 
            />
          </div>
        </div>
      </div> */}
    </div> {/* Close Carosuel  */}
  </div>
}

export default CustomerStories