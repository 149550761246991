import React from 'react'
import { ICustomerStoriesCarouselId } from '..';


interface ICustomerStoriesIndicators extends ICustomerStoriesCarouselId {
  indicatorText: string[];
}

const CarouselIndicators: React.FC<ICustomerStoriesIndicators> = ({ id, indicatorText }) => {
  return <div className="carousel-indicators text-indicators">
    {indicatorText && indicatorText.map((text, i) => <button 
      key={i} 
      type="button" 
      data-bs-target={`#${id}`} 
      data-bs-slide-to={i}
      aria-label={`Slide ${i+1}`}
      className={i === 0 ? "active" : undefined} 
      aria-current={i === 0 ? "true" : undefined}
    >
      {text && text}
    </button>)}
  </div>
}

export default CarouselIndicators